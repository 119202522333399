.analytics-filters__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.analytics-filters__title {
  font-size: 20px;
  font-weight: 600;
}

.analytics-filters__clear-button {
  color: var(--red);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

.analytics-filters__date-inputs {
  display: flex;
  gap: 16px;
}

.analytics-filters__date-inputs input {
  width: 104px;
}
