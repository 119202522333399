@media (min-width: 900px) {
  .case-items {
    display: flex;
    gap: 16px;
  }

  .case-items__list-container {
    width: 40%;
  }

  .case-items__form-container {
    flex: 1;
  }
}
