.calendar-view {
  width: 100%;
  padding: 20px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.calendar-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.calendar-header-cell {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.calendar-day {
  min-height: 120px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
}

.calendar-day.weekend {
  background-color: #f9f9f9;
}

.calendar-day.outside-month {
  color: #999;
}

.calendar-day-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.calendar-day-number {
  font-weight: bold;
}

.calendar-month-label {
  font-size: 0.85em;
  color: #666;
  margin-right: 4px;
}

.calendar-case-item {
  font-size: 0.9em;
  margin-bottom: 8px;
  padding: 6px;
  background-color: #f0f7ff;
  border-radius: 4px;
  border-left: 3px solid #2196f3;
}
.calendar-case-link {
  text-decoration: none;
}

.calendar-case-item:last-child {
  margin-bottom: 0;
}

.calendar-case-code {
  font-weight: 500;
  margin-bottom: 4px;
}

.calendar-case-item--complete {
  background-color: var(--green-light);
  border-left: 3px solid var(--green);
} 
.calendar-case-process-name {
  font-size: 12px;
  color: var(--gray-dark);
  text-decoration: none;
}