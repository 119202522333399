.view-case__header-main {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.view-case__process-name-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.view-case__process-name {
  margin: 0;
  padding-right: 8px;
  border-right: 1px solid var(--gray-200);
}

.view-case__process-name-container button {
  margin-bottom: 0 !important;
  text-decoration: none;
  color: var(--blue);
  display: flex;
  align-items: center;
  gap: 4px;
}

.view-case__nickname-form {
  display: flex;
  align-items: center;
  gap: 8px;
}

.view-case__code {
  margin: 0;
  font-size: 28px;
}

.view-case__code--small {
  font-size: 20px;
  color: var(--gray-400);
}

.view-case__code-container {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.view-case__header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.view-case__bottom-panel {
  min-height: 500px;
}

@media (min-width: 1100px) {
  .view-case__container {
    display: flex;
    gap: 16px;
    height: calc(100% - 16px);
  }

  .view-case__main-panel {
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .view-case__side-panel {
    max-width: 400px;
    width: 30%;
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .view-case__side-panel--collapsed {
    max-width: 72px;
    width: 72px;
  }

  .view-case__side-panel--collapsed .react-tabs__tab-list {
    flex-direction: column;
    border-bottom: none;
  }

  .view-case__side-panel--collapsed .react-tabs__tab {
    padding: 12px 8px !important;
    min-width: auto;
    text-align: center;
    border: none !important;
    border-bottom: 1px solid var(--gray-200) !important;
    margin: 0;
  }

  .view-case__side-panel--collapsed .react-tabs__tab:last-child {
    border-bottom: none !important;
  }

  .view-case__side-panel--collapsed .react-tabs__tab--selected {
    background: var(--blue-light);
    color: var(--blue);
  }

  .view-case__side-panel .react-tabs__tab-list {
    display: flex;
    transition: all 0.3s ease-in-out;
  }

  .view-case__side-panel-toggle {
    position: absolute;
    top: 64px;
    left: -12px;
    background: var(--gray-100);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid var(--gray-300);
    cursor: pointer;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .view-case__side-panel-toggle svg {
    width: 14px;
    height: 14px;
  }

  .view-case__side-panel .react-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .view-case__side-panel .react-tabs__tab-panel {
    flex: 1;
    overflow: auto;
  }

  .view-case__side-panel .react-tabs__tab-panel--selected {
    height: 100%;
  }

  .view-case__contents {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .view-case__bottom-panel {
    flex: 1;
    height: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
