.risk-input {
  display: flex;
  gap: 8px;
}

.risk-input__left {
  flex: 1;
}

.risk-input__right {
  flex: 1;
}